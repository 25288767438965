
export let onBoardingCfg = {
  'landing': [
    {
      id: 0,
      customClass: 'landing-main'
    },
    {
      element: ".upload-items",
      id: 'upload'
    },
    {
      element: ".is-first-landing-item",
      id: 'dataset'
    },
    {
      element: ".preview-panel .tooltip-helper-class-open-data-view-button",
      id: 'openDataViewButton'
    }
  ],
  'dataview': [
    {
      id: 1,
      customClass: 'dataview-main landing-main'
    },
    {
      id: 'exploreButton',
      element: '.header-cell-action-wrapper .mm-tooltip--slot-wrapper svg[data-name=explore]'
    },
    {
      id: 'exploreCard',
      element: '.explore-panel-wrapper'
    },
    {
      id: 'addTaskButton',
      element: '.action-bar-button--transform'
    },
    {
      id: 'pipeline',
      element: '.action-bar-button--pipeline'
    },
    // {
    //   element: '.dataset .dataset-views a.add-item',
    //   id: 'addView'
    // },
    // {
    //   id: 'combine',
    //   element: '.action-bar-button--combine'
    // },
    {
      id: 'exportButton',
      element: '.action-bar-button--export'
    },
    // {
    //   element: ".elements-open div.add-element",
    //   id: 'elementsPlus'
    // },
    // {
    //   element: '.elements-panel .toggle-element-panel',
    //   id: 'metricsPaneltoggle'
    // },
    // {
    //   element: '.notification-icon',
    //   id: 'notification'
    // }
  ]
};
