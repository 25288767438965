'use strict';
import * as RRule from 'rrule-alt';
import * as _ from 'lodash-es';

var aggregations = {
  sum: 'SUM',
  avg: 'AVG',
  min: 'MIN',
  max: 'MAX',
  stddev: 'STDDEV',
  count: 'COUNT',
  concat: 'CONCAT'
};


export let constants = {
  HOME: 'home',
  PUBLISHED_REPORTS: 'published-reports',
  SYSTEM_TIME: '__TIME__',
  text: "TEXT",
  numeric: "NUMERIC",
  date: "DATE",
  sequenceNumber: 'SEQUENCE_NUMBER',
  includeRowCount: 'INCLUDE_ROW_COUNT',

  POSTGRES_DATE_FORMAT: 'postgres_date_format',
  DATE_FORMAT: 'date_format',

  RESERVED_BATCH_COLUMN_NAMES: ['batch_ID', 'batch_RowCount', 'batch_Date', 'batch_Source', 'batch_Name'],

  saveAsDSCreateColIdentifier: '-- Create a new column --',

  aggregations: aggregations,
  aggregationsArray: [
    'COUNT', 'SUM', 'AVG', 'MIN', 'MAX', 'STDDEV'
  ],
  requestZipFileUploadSize: 10, //MB
  maxFileUploadSize: 256, //MB

  splitFeatureFlags: {
    SFTP: "sftp",
    TEMPLATES: "templates",
    ALERTS: "alerts",
    MINUTELY_DATA_PULL: "minutelyDataPull",
    HOURLY_DATA_PULL: "hourlyDataPull",
    SAMPLE_MODE: "sampleMode",
    BRANCH_OUT_TO_ANY: "branchOutToAny",
    REGEX: "regex",
    SEARCH_BAR: "searchBar",
    COLLAPSE_ROWS: "collapseRows",
    PIPELINE_SNOOZE: "pipelineSnooze",
    REPLACE_OPTION_IN_COMBINE_DS: "replaceOptionInCombineDs",
    COLLAPSE_DATAVIEWS: "collapseDataviews",
    PUBLISHDB: "publishDB",
    GLOBAL_PENDING_UPDATES: "globalPendingUpdates",
    DATA_FLOW_MAP: "dataFlowMap",
    SUBSCRIPTION_PLAN: "subscriptionPlan",
    BULK_REPLACE_THRESHOLD: "bulkReplaceThreshold",
    DUPLICATE_VIEW_WITH_DS: "duplicateViewsWithDs",
    BIGQUERY_SMART_COMBINE: "bigquerySmartCombine",
    JOIN_BLOAT_WARNING: "joinBloatWarning",
    CURRENT_TIME_IN_LABEL: "currentTimeInLabel",
    CURRENT_TIME_IN_DATE_DIFF: "currentTimeInDateDiff",
    COMBINE_DS_COLUMN_MAPPING_FEATURES: "combineDsColumnMappingFeatures",
  },
  sortOptions: {
    modified: "modified",
    name: "name"
  },
  actions: {
    mysql: 'mysql',
    postgres: 'postgres',
    redshift: 'redshift',
    elasticsearch: 'elasticsearch',
    s3: 's3',
    internal_ds: 'internal_dataset',
    powerbi: 'powerbi',
    mssql: 'mssql',
    bigquery: 'bigquery',
    sftp: 'sftp'
  },
  errorCodetoMessageMap: {
    35: "Column name provided is already being use in the pipeline. Try giving name other than ",
    153: "Column mappings are no longer valid.",
    154: "Current settings are affected due to schema change",
    139: "The result dataset has been deleted",
    159: "Could not validation condition",
    1632: "Invalid Param - Cannot keep the action at the end of pipeline",
    1613: "Cycle detected in dataview dependents",
    1638: "Column limit exceeded (400). Please choose a column with fewer unique values.",
    7011: "Error upon validating this task",
    999: "Runtime error. Contact support for further help"
  },
  pipeline_error_codes: {
    runtime_exception: 7000,
    reference_error: 7001,
    exception_while_error_checking: 7002,
    column_type_mismatch: 7003,
    column_missing: 7004,
    dependency_pipeline_in_error: 7005,
    not_allowed: 7006,
    dependency_pipeline_does_not_exist: 7007,
    duplicate_columns: 7008,
    dependency_pipeline_out_of_sync: 7009,
    dependencies_affected: 7010,
    validation_exception: 7011
  },
  column_missing_template: (column_names, plural = false) => `Column: ${column_names} ${plural?'are':'is'} missing.`,
  column_type_mismatch_template: (column_names, plural = false) => `Column: ${column_names} ${plural ? 'are' : 'is'} not type compatible.`,
  resourceTypes: {
    ds: "datasource",
    ws: "dataview",
    file: "file_object",
    upload: "uploading_file",
    report: 'report',
    label: 'label'
  },

  wsStatus: {
    ready: "ready",
    processing: "processing"
  },
  pipelineStatus: {
    ready: "ready",
    submitted: "submitted",
    running: "running",
    error: "error"
  },

  futureStatus: {
    success: "success",
    processing: "processing",
    error: "error"
  },
  reportStatus: {
    ready: "ready",
    processing: "processing",
    deleting: "deleting"
  },
  stepStatus: {
    deleted: 'deleted',
    executed: 'executed',
    edited: 'edited',
    added: 'added',
    suspended: 'suspended',
    suspending: 'suspending'
  },

  eventCodeMap: {
    rightClick: 3
  },
  dsStatus: {
    ready: "ready",
    unprocessed: "unprocessed",
    processing: "processing",
    actionNeeded: "need_action",
    hasUnstructuredData: "has_unstructured_data",
    duplicating: "duplicating",
    deleted: "deleted",
    deleting: "deleting",
    merging: "merging",
    appending: "appending",
    error: "error",
    empty: "empty"
  },
  batchSourceInfo: {
    URL: 'URL',
    SOURCE_ID: 'SOURCE_ID',
    DATAVIEW: 'DATAVIEW',
    DATAVIEW_ID: 'DATAVIEW_ID',
    TEXT: 'TEXT',
    EXTRA_INFO: 'EXTRA_INFO',
    INTERNAL_SOURCE: 'INTERNAL_SOURCE',
  },
  batch_table_colums_list: [
    { display_name: 'batch_ID', type: 'NUMERIC', key: 'id' },
    { display_name: 'batch_RowCount', type: 'NUMERIC', key: 'count' },
    { display_name: 'batch_Date', type: 'DATE', key: 'created_at' },
    { display_name: 'batch_Source', type: 'TEXT', key: 'source' },
    { display_name: 'batch_Name', type: 'TEXT', key: 'name' }
  ],
  landingItemStatus: {
    moving: "moving",
  },
  fileStatus: {
    error: "error",
    actionNeeded: "action_needed",
    extracting: "extracting",
    extracted: "extracted",
    processing: "processing",
    processed: "processed",
    deleting: "deleting",
    hasUnstructuredData: "has_unstructured_data"
  },
  coreListObjectStatus: {
    created: 'created',
    updated: 'updated',
    deleted: 'deleted',
  },
  elementTypes: {
    METRIC: 'METRIC',
    TABLE: 'TABLE',
    CHART: 'CHART',
    FILTER: 'FILTER',
    FUNNEL: 'FUNNEL'
  },
  decimalPlacesOptions: [
    { display: 0, value: 0 },
    { display: 0.1, value: 1 },
    { display: 0.12, value: 2 },
    { display: 0.123, value: 3 },
    { display: 0.1234, value: 4 },
    { display: 0.12345, value: 5 },
    { display: 'unspecified', value: null }
  ],
  explorePanel: {
    text: {
      LIMIT: 40000
    }
  },
  currencyOptions: ["€", "$", "¥", "£", "₹"],
  chartTypes: {
    xy: 'xy',
    pie: 'pie',
    funnel: 'funnel'
  },
  get numericDefaultFormat() {
    return {
      comma_separated: true,
      decimal_spec: -1
    }
  },
  defaultDatePickerFormat: 'Y-m-d',
  defaultDateTimePickerFormat: 'Y-m-d H:i:S',
  get dateTruncateOptions() {
    return [
      { display: "Month", value: "%B-%Y", param: { TRUNCATE: 'MONTH' }, id: 1 },
      { display: "Week Ending", value: '%Y/%m/%d', param: { TRUNCATE: 'WEEK' }, id: 2 },
      { display: "Day", value: "%Y/%m/%d", param: { TRUNCATE: 'DAY' }, id: 3 },
      { display: "Hour", value: '%Y/%m/%d %H:%M:%S', param: { TRUNCATE: 'HOUR' }, id: 4 },
      { display: "Minute", value: '%Y/%m/%d %H:%M:%S', param: { TRUNCATE: 'Minute' }, id: 5 }
    ]
  },
  get dateTruncateExtractOptions() {
    var values = [
      { display: "YYYY-MM-DD", value: "%Y-%m-%d", param: { TRUNCATE: 'DAY' } },
      { display: "Mmm-YYYY", value: "%b-%Y", param: { TRUNCATE: 'MONTH' } },
      { display: "Week Ending", value: '%Y-%m-%d', param: { TRUNCATE: 'WEEK' } },
      { display: "YYYY-MM-DD HH:00", value: "%Y-%m-%d %H:%M", param: { TRUNCATE: 'HOUR' } },
      { display: "YYYY-MM-DD HH:MM", value: "%Y-%m-%d %H:%M", param: { TRUNCATE: 'MINUTE' } },
      { display: "Year", value: null, param: { COMPONENT: 'year' } },
      { display: "Month", value: null, param: { COMPONENT: 'month_text' } },
      { display: "Hour", value: null, param: { COMPONENT: 'hour' } },
      { display: "Day of week", value: null, param: { COMPONENT: 'weekday_text' } },
      { display: "All values", value: "%Y/%m/%d %H:%M:%S", param: {} }
    ];
    _.forEach(values, function(v, i) {
      v['id'] = i + 1;
    });
    return values;
  },
  dateServerSideFormat: '%d-%b-%Y %I:%M %p',
  datetimeServerSideFormat: '%Y-%m-%d %H:%M:%S',
  COLUMN_SUMMARIES: "COLUMN_SUMMARIES",
  EXPLORE_PANEL: "EXPLORE_PANEL",
  COLUMN_SUMMARY: "COLUMN_SUMMARY",
  GLOBAL_DISPLAY_CONDITION: "GLOBAL_DISPLAY_CONDITION",
  USER_DISPLAY_PROPERTIES: "USER_DISPLAY_PROPERTIES",
  SHOW_CUSTOM_CARD: 'SHOW_CUSTOM_CARD',
  SUMMARY_TYPES: {
    AGGREGATIONS: "AGGREGATIONS",
    UNIQUE_VALUES: "UNIQUE_VALUES"
  },
  modeOptions: [
    {
      name: "Combine with older data",
      value: "Combine"
    },
    {
      name: "Replace the older data",
      value: "Replace"
    },
  ],
  appHelpRoot: 'https://docs.mammoth.io/content/feature_guide/',
  appHelp: {
    'tasks': {
      'filter': 'tasks/applyfilter',
      'limit': 'tasks/showtopbottom',
      'duplicates': 'tasks/remove.duplicates',
      'pivot': 'tasks/group',
      'unnest': 'tasks/columns.to.rows',
      'replace': 'tasks/findandreplace',
      'bulkReplace': 'tasks/bulkreplace',
      'lookup': 'tasks/lookup',
      'join': 'tasks/join.views',
      'pivot_table': 'tasks/crosstab',
      'internal_dataset': 'tasks/branch.out.to.dataset',
      'addColumn': 'tasks/new.column',
      'delete': 'tasks/remove.column',
      'insert': 'tasks/labels',
      'combine': 'tasks/combine.column',
      'split': 'tasks/split.column',
      'copy': 'tasks/copy.column.values',
      'convert': 'tasks/convert.columns',
      'math': 'tasks/math.rule',
      'rTotal': 'tasks/window.function#runningtotal',
      'rank': 'tasks/window.function#rank',
      'window_function': 'tasks/window.function',
      'window.aggregation': 'tasks/window.function#aggregation-functions',
      'window.sum': 'tasks/window.function#sum',
      'window.avg': 'tasks/window.function#average',
      'window.min': 'tasks/window.function#minimum',
      'window.max': 'tasks/window.function#maximum',
      'window.stddev': 'tasks/window.function#standard-deviation',
      'window.variance': 'tasks/window.function#variance',
      'window.count': 'tasks/window.function#count',
      'window.ranking': 'tasks/window.function#ranking-functions',
      'window.rownum': 'tasks/window.function#row-number',
      'window.rank': 'tasks/window.function#rank',
      'window.dense_rank': 'tasks/window.function#dense-rank',
      'window.percent_rank': 'tasks/window.function#percent-rank',
      'window.cum_percent_rank': 'tasks/window.function#cumulative-percent-rank',
      'window.ntile': 'tasks/window.function#ntile',
      'window.relative': 'tasks/window.function#relative-functions',
      'window.first_val': 'tasks/window.function#first-value',
      'window.last_val': 'tasks/window.function#last-value',
      'window.nth_val': 'tasks/window.function#nth-value',
      'window.lead': 'tasks/window.function#lead',
      'window.lag': 'tasks/window.function#lag',
      'small_or_large': 'tasks/small.large',
      'format_text': 'tasks/text.transform',
      'json_handle': 'tasks/json.extract',
      'extract_text': 'tasks/text.extract',
      'increment_date': 'tasks/increment.decrement.date',
      'extract_date': 'tasks/date.extract',
      'date_diff': 'tasks/date.diff',
      'fillValues': 'tasks/fill.missing.values',
      'collapseRows': 'tasks/concatenate.values',
      'mysql': 'export_data/MySQL',
      'postgres': 'export_data/postgressql',
      'elasticsearch': 'export_data/elasticsearch',
      'powerbi': 'actions/export.data#export-to-power-bi',
      'mssql': "export_data/sqlserver",
      'redshift': 'export_data/awsredshift',
      'bigquery': 'export_data/exporttobigquery',
      'publishdb':'publish.as.a.database'

    }
  },
  columnMessages:{
    'not available': 'Column missing. Try another one',
    'type mismatch': 'Incompatible column type',
  },
  userEvents: {
    userLoggedIn: "userLoggedIn",
    userLoggedOut: "userLoggedOut",
    workspaceSelected: "workspaceSelected",
    exploreCards: {
      dragged: "exploreCardDragged",
      dropped: "exploreCardDropped",
      repositioned: "exploreCardRepositioned",
      saveChart: 'saveChart',
      sort: "exploreCardSort",
      search: 'exploreCardSearch',
      add: 'explorePanelCardAdded',
      loaded: 'explorePanelCardLoaded',
      switchExploreCardView: 'switchExploreCardView',
      removed: 'explorePanelCardRemoved',
      changeAggregation: 'exploreCardAggregationChanged',
      filtered: 'explorePanelCardFilterSet',
      filterRemoved: 'explorePanelCardFilterRemoved',
      filterRemovedFromCustomExploreCard: 'filterRemovedFromCustomExploreCard',
      addConditionInCustomExploreCard: 'addConditionInCustomExploreCard',
      filterResetAll: 'explorePanelCardFilterResetAll',
      exploreToggled: 'explorePanelToggled',
      metricAdded: 'metricAddedFromExplorePanel',
      chartAdded: 'chartAddedFromExplorePanel',
      replaceTextFromExploreMenu: 'replaceTextFromExploreMenu',
      filterRuleFromExploreMenu: 'filterRuleFromExploreMenu',
      createNewViewWithFiltersApplied: 'createNewViewWithFiltersApplied',
      unsetItemFromExploreCardFilter: 'unsetItemFromExploreCardFilter',
      removeAllValuesLikeThis: 'removeAllValuesLikeThis',
      keepAllValuesLikeThis: 'keepAllValuesLikeThis',
      replaceAllValuesLikeThis: 'replaceAllValuesLikeThis',
      copyValueToClipboard: 'copyValueToClipboard',
      exportListAsCSV: 'exportListAsCSV'
    },
    settingsPage: {
      sectionChanged: "sectionChanged",
      userRemoved: "userRemoved",
      security: {
        twoFactorAuthSettingsChanged: "twoFactorAuthSettingsChanged",
        twoFactorAuthSetup: "twoFactorAuthSetup",
        twoFactorAuthRenewed: "twoFactorAuthRenewed"
      }
    },
    landingPage: {
      addNewDS: {
        webhookDs: {
          selectOptions: "selectOptions",
          closeModal: "closeModal",
          createWebhookDs: "createWebhookDs"
        },
        setSampleUrl: "setSampleUrl",
        submitUrl: "submitUrl",
        addnewDSClicked: "addNewDS.Clicked",
        addnewDSActiveOption: "addNewDS.toggleOption",
        closeModal: "closeModal",
        thirdParty: {
          addnewDSAPINewConnection: "addNewDS.API.newConnection",
          newAPIIntegrationSelected: "addNewDS.API.newAPIIntegrationSelected",
          removeConnection: "removeConnection",
          addNewAPIConnectClicked: "addNewDS.API.newAPIIntegrationConnect",
          selectIdentity: "selectIdentity",
          selectProfile: "selectProfile",
          gotoPreviousStep: "gotoPreviousStep",
          gotoNextStep: "gotoNextStep",
          cancelButtonClicked: "cancelButtonClicked",
          schedulingOptions: {
            changeScheduleFrequency: "changeScheduleFrequency",
            changeScheduleOptions: "changeScheduleOptions",
            changeOnRefreshOption: "changeOnRefreshOption",
            changeUniqueSeqCol: "changeUniqueSequenceColumn"
          },
          submitClicked: "submitClicked",
          changeDsName: "changeDsName",
          salesForce: {
            selectObject: "selectObject"
          },
          ggl_anltcs: {
            onSearchChange: "onSearchChange",
            onSegmentChange: "onSegmentChange",
            validateMetricsDimensions: "validateMetricsDimensions"
          },
          mixpanel: {
            datapullOptionChange: "datapullOptionChange"
          },
          rdbms: {
            selectTable: "selectTable",
            runTestQuery: "runTestQuery"
          }
        }

      },
      fileUploaded: "fileUploaded",
      selectDS: "selectDS",
      previewPanel: {
        addOrReplaceDataClicked: "addOrReplaceDataClicked",
        combineData: {
          combineDSClicked: "combineDS.Clicked",
          combineDSSelect: "combineDS.Select",
          combineDSNext: "combineDS.Next",
          combineDSPrevious: "combineDS.Previous",
          combineDSConfirm: "combineDS.Confirm"
        },
        deleteBatches: "deleteBatches",
        inlineRenameDS: "inlineRenameDS",
        renameDS: "renameDS",
        createDuplicateDS: "createDuplicateDS",
        deleteFolder: "deleteFolder",
        deleteDS: "deleteDS",
        openDataView: "openDataView",
        syncData: "syncData",
        goToFolder: "goToFolder",
        previewBatch: "previewBatch",
        dropBatches: "dropBatches",
        suspendUnsuspendBatches: "suspendUnsuspendBatches",
        batchColumnsUpdate: "batchColumnsUpdate",
        closePreviewPanel: "closePreviewPanel",
        toggleAutoSync: "toggleAutoSync",
        propertySectionCollapsed: "propertySectionCollapsed",
        previewSectionCollapsed: "previewSectionCollapsed",
        filesAndFoldersSectionCollapsed: "filesAndFoldersSectionCollapsed",
        refreshButtonClicked: "refreshButtonClicked",
        deletedBatch: "deletedBatch",
        deletedMultipleBatches: "deletedMultipleBatches",
        suspendBatches: "deletedBatches",
        resolveButtonClicked: "resolveButtonClicked",
        settingsDone: "settingsDone",
        previewClicked: "previewClicked",
        copyWebhookUrl: "copyWebhookUrl",
        editWebhookOriginSites: "editWebhookOriginSites",
        editWebhookMode: "editWebhookMode",
        toggleWebHookSecurityHeader: "toggleWebhookSecurityHeader",
        editScheduleConfig: "editScheduleConfig",
        scheduledUpdate: "scheduledUpdate",
        filePasswordSubmit: "filePasswordSubmit",
        pullCloudData: "pullCloudData",
      },
      openUnstructureDataModal: "openUnstructureDataModal",
      sortDatasets: "sortDatasets",
      multiDeleteDatasets: "multiDeleteDatasets",
      searchDS: "searchDS"
    },

    dataviewEvents: {
      goToSettingsPage: "goToSettingsPage",
      goToLandingPage: "goToLandingPage",
      openDataSettings: "opendataSettings",
      actionAdded: "actionAdded",
      reorderTask: "reorderTask",
      refreshWebHook: "refreshWebHook",
      viewOptions: {
        openNewView: "viewOptions.openNewView",
        renameView: "viewOptions.renameView",
        resetView: "viewOptions.resetView",
        deleteView: "viewOptions.deleteView",
        scrollRight: "scrollToRightOnViewsPanel",
        scrollLeft: "scrollToLeftOnViewsPanel"
      },
      taskMenuToggle: "taskMenuToggle",
      taskPanelEvents: {
        taskPanelToggle: "taskPanelEvents.taskPanelToggle",
        taskAdded: "taskPanelEvents.taskAdded",
        taskInvoked: "taskPanelEvents.taskInvoked",
        taskDeleted: "taskPanelEvents.taskDeleted",
        taskEdited: "taskPanelEvents.taskEdited",
        taskRestored: "taskPanelEvents.taskRestored",
        taskSuspended: "taskPanelEvents.taskSuspended",
        taskRenamed: "taskPanelEvents.taskRenamed",
        addTaskNote: "addTaskNote",
        enteringStepPreviewMode: "enteringStepPreviewMode",
        exitingStepPreviewMode: "exitingStepPreviewMode"
      },
      togglePipelineDetails: "togglePipelineDetails",
      actionMenuEvents: {
        actionMenuToggle: "actionMenuEvents.actionMenuToggle",
        actionSettings: "actionMenuEvents.actionSettings",
        actionDeleted: "actionMenuEvents.actionDeleted",
        runAction: "actionMenuEvents.runAction",
        addAction: "actionMenuEvents.addAction",
        editAction: "actionMenuEvents.editAction"
      },
      columnBrowser: {
        toggle: 'columnBrowser.toggle',
        hide: 'columnBrowser.hide',
        show: 'columnBrowser.show',
        showOnly: 'columnBrowser.showOnly',
        reorder: 'columnBrowser.reorder',
        rename: 'columnBrowser.rename',
        remove: 'columnBrowser.remove',
        replace: 'columnBrowser.replace',
        convert: 'columnBrowser.convert',
        openInExploreColumns: 'columnBrowser.openInExploreColumns',
        columnClick: 'columnBrowser.columnClick'
      },
      columnMenuEvents: {
        selectColumnMenu: "selectColumnMenu",
        hideColumn: "hideColumn",
        renameColumn: "renameColumn",
      },
      reorderColumns: "reorderColumns",
      highlightAndScrollColumns: "highlightAndScrollColumns",
      resetDataGrid: "resetDataGrid",
      toggleSelectMenu: "toggleSelectMenu",
      toggleModifyColumnsPanel: "toggleModifyColumnsPanel",
      toggleFindColumnPanel: "toggleFindColumnPanel",
      sortDataview: "sortDataview",
      addAnotherColumnToSort: "addAnotherColumnToSort",
      removeColumnFromSort: "removeColumnFromSort",
      removeDataviewSort: "removeDataviewSort",
      removeFilterCondition: "removeFilterCondition",
      findColumnPanelSearchChange: "findColumnPanelSearchChange",
      modifyColumnsPanelSearchChange: "modifyColumnsPanelSearchChange",
      columnsModified: "columnsModified",
      exploreJoinWarning: "exploreJoinWarning",
      linkedDsEvents: {
        linkedDS: "linkedDS",
        configureLinkedDs: "configureLinkedDs",
        unlinkDs: "unlinkDs"
      },
    },
    exportEvents: {
      exportDailogOpened: "exportDailogOpened",
      successfull: "exportSuccessfull",
      failure: "exportFailed",
      exportFormatChanged: "exportFormatChanged",
      exportHiddenColumnsChanged: "exportHiddenColumnsChanged",
      exportOptionChanged: "exportOptionChanged",
      onTargetUserWorkspaceChange: "exportTargetWorkspaceChanged",
      changeDbOption: "changeDbOption",
      cancelButtonClicked: "cancelButtonClicked"
    },
    alertEvents: {
      createNewAlert: "createNewAlert",
      editAlert: "editAlert",
      deleteAlert: "deleteAlert"
    },
    publishEvents: {
      addPublish: "addPublish",
      openCredentials: "openCredentials",
      publishPasswordReset: "publishPasswordReset",
      deletePublish: "deletePublish",
    },
    notificationEvents: {
      togglePanel: "Toggle Panel",
      dismissNotification: "Dismiss Notification",
      deleteNotification: "Delete Notification",
      showMore: "Show more",
      showLess: "Show less",
      clickDownload: "Click Download"
    },
    elements: {
      editor: {
        addOpened: "elements.editor.addOpened",
        editOpened: "elements.editor.editOpened",
        changeChartType: "elements.editor.changeChartType",
        tabSelected: "elements.editor.tabSelected",
        elementTypeSelected: "elements.editor.elementTypeSelected",
        nonPivotChartAggChanged: "elements.editor.nonPivotChartAggChanged",
        columnChanged: "elements.editor.columnChanged",
        columnRemoved: "elements.editor.columnRemoved",
        aggregationAdded: "elements.editor.aggregationAdded",
        columnAdded: "elements.editor.columnAdded",
        toggleSelectors: "toggleSelectors",
        functionSelected: "functionSelected",
        toggleScientificFormatOption: "toggleScientificFormatOption",
        metricTitleChanged: "metricTitleChanged",
        metricSubtitleChanged: "metricSubtitleChanged",
        metricNoteChanged: "metricNoteChanged",
        toggleTab: "toggleTab",
        closeModal: "closeModal",
        openFilterConditionBuilderModal: "openFilterConditionBuilderModal",
        closeFilterConditionBuilderModal: "closeFilterConditionBuilderModal",
        addFilterCondition: "addFilterConditionOnMetric",
        caseSensitive: "caseSensitive"

      },
      panel: {
        toggled: "elements.panel.toggled",
        fullscreenToggled: "elements.panel.fullscreenToggled",
        expandedState: "elements.panel.expandedStateToggled",
        gridViewToggled: "elements.panel.gridViewToggled",
      },
      deleteTriggered: "elements.deleteTriggered",
      delete: "elements.delete",
      add: "elements.add",
      edit: "elements.edit",
      duplicated: "elements.duplicated",
      resized: "elements.resized",
      reordered: "elements.reordered",
      copyMetricValueToClipboard: "copyMetricValueToClipboard",
      toggleMetricValueLock: "toggleMetricValueLock"
    },
    onboarding: {
      start: "onboarding.start",
      change: "onboarding.change",
      exit: "onboarding.exit",
      complete: "onboarding.complete"
    },
    hints: {
      turnOn: "hints.turnOn",
      enterHover: "hints.enterHover",
      leaveHover: "hints.leaveHover",
      turnOff: "hints.turnOff",
      clicked: "hints.clicked"
    },
    gotoDoc: "gotoDocumentation",
    helpSupport: "helpSupport",
    createNewFolder: "createNewFolder",
    openNewFolderModal: "openNewFolderModal",
    moveToFolder: "moveToFolder",
    cannyFeatureRequestClick: "cannyFeatureRequestClick",
    cannyChangelogClick: "cannyChangelogClick"
  },

  PERMISSIONS: {
    GUEST: 'guest',
    SUPER_ADMIN: "super_admin",
    OWNER: "owner",
    ADMIN: "admin",
    ANALYST: "analyst"
  },
  PERMISSIONS_DISPLAY: {
    guest: 'Guest',
    super_admin: "Super Admin",
    owner: "Owner",
    admin: "Admin",
    analyst: "Analyst"
  },
  ROLES: {
    WORKSPACE_GUEST: 'workspace_guest',
    WORKSPACE_SUPER_ADMIN: "workspace_super_admin",
    WORKSPACE_OWNER: "workspace_owner",
    WORKSPACE_ADMIN: "workspace_admin",
    WORKSPACE_MEMBER: "workspace_member"
  },
  ROLES_DISPLAY: {
    workspace_guest: 'Workspace Guest',
    workspace_super_admin: "Super Admin",
    workspace_owner: "Workspace Owner",
    workspace_admin: "Workspace Admin",
    workspace_member: "Workspace Member"
  },
  USER_STATUSES: {
    ACIVE: "ACTIVE"
  },
  get frequencyRRuleMap() {
    return {
      'WEEKLY': RRule.WEEKLY,
      'MONTHLY': RRule.MONTHLY,
      "MINUTELY": RRule.MINUTELY,
      "HOURLY": RRule.HOURLY,
      "DAILY": RRule.DAILY
    }
  },

  get weekdayRRuleMap() {
    return {
      "MO": RRule.MO,
      "TU": RRule.TU,
      "WE": RRule.WE,
      "TH": RRule.TH,
      "FR": RRule.FR,
      "SA": RRule.SA,
      "SU": RRule.SU
    }
  },

  get weekdays() {
    return [
      {
        key: 'MO',
        value: 'Monday'
      },
      {
        key: 'TU',
        value: 'Tuesday'
      },
      {
        key: 'WE',
        value: 'Wednesday'
      },
      {
        key: 'TH',
        value: 'Thursday'
      },
      {
        key: 'FR',
        value: 'Friday'
      },
      {
        key: 'SA',
        value: 'Saturday'
      },
      {
        key: 'SU',
        value: 'Sunday'
      }
    ];
  },

  get PY_WEEKDAYS() {
    return [6, 0, 1, 2, 3, 4, 5];
  },

  get dateOptions() {

    return {
      general: [
        { display: "2021/03/26", value: "%Y/%m/%d" },
        { display: "2021/03/26 14:30:45", value: "%Y/%m/%d %H:%M:%S" },
        { display: "2021/03/26 02:30:45 pm", value: "%Y/%m/%d %I:%M:%S %p" },
        { display: "2021-03-26", value: "%Y-%m-%d" },
        { display: "2021-03-26 14:30:45", value: "%Y-%m-%d %H:%M:%S" },
        { display: "2021-03-26 02:30:45 pm", value: "%Y-%m-%d %I:%M:%S %p" },
        { display: "26-Mar-21", value: "%d-%b-%y" },
        { display: "26-Mar-21 14:30:45", value: "%d-%b-%y %H:%M:%S" },
        { display: "26-Mar-21 02:30:45 pm", value: "%d-%b-%y %I:%M:%S %p" },
        { display: "26-Mar-2021", value: "%d-%b-%Y" },
        { display: "26-Mar-2021 14:30:45", value: "%d-%b-%Y %H:%M:%S" },
        { display: "26-Mar-2021 02:30:45 pm", value: "%d-%b-%Y %I:%M:%S %p" },
        { display: "March 26, 2021", value: "%B %d, %Y" },
        { display: "Friday, March 26, 2021", value: "%A, %B %d, %Y" },
        {
          display: "Friday, March 26, 2021 14:30:45",
          value: "%A, %B %d, %Y %H:%M:%S"
        },
        {
          display: "Friday, March 26, 2021 02:30:45 pm",
          value: "%A, %B %d, %Y %I:%M:%S %p"
        }
      ],
      US: [
        { display: '03-26-21', value: '%m-%d-%y' },
        { display: '03-26-2021', value: '%m-%d-%Y' },
        { display: '03-26-21 14:30:45', value: '%m-%d-%y %H:%M:%S' },
        { display: '03-26-21 02:30:45 pm', value: '%m-%d-%y %I:%M:%S %p' },
        { display: '03-26-2021 14:30:45', value: '%m-%d-%Y %H:%M:%S' },
        { display: '03-26-2021 02:30:45 pm', value: '%m-%d-%Y %I:%M:%S %p' },
        { display: '03/26/21', value: '%m/%d/%y' },
        { display: '03/26/2021', value: '%m/%d/%Y' },
        { display: '03/26/21 14:30:45', value: '%m/%d/%y %H:%M:%S' },
        { display: '03/26/2021 14:30:45', value: '%m/%d/%Y %H:%M:%S' },
        { display: '03/26/21 02:30:45 pm', value: '%m/%d/%y %I:%M:%S %p' },
        { display: '03/26/2021 02:30:45 pm', value: '%m/%d/%Y %I:%M:%S %p' }
      ],
      UK: [
        { display: '26-03-21', value: '%d-%m-%y' },
        { display: '26-03-2021', value: '%d-%m-%Y' },
        { display: '26-03-21 14:30:45', value: '%d-%m-%y %H:%M:%S' },
        { display: '26-03-21 02:30:45 pm', value: '%d-%m-%y %I:%M:%S %p' },
        { display: '26-03-2021 14:30:45', value: '%d-%m-%Y %H:%M:%S' },
        { display: '26-03-2021 02:30:45 pm', value: '%d-%m-%Y %I:%M:%S %p' },
        { display: '26/03/21', value: '%d/%m/%y' },
        { display: '26/03/2021', value: '%d/%m/%Y' },
        { display: '26/03/21 14:30:45', value: '%d/%m/%y %H:%M:%S' },
        { display: '26/03/2021 14:30:45', value: '%d/%m/%Y %H:%M:%S' },
        { display: '26/03/21 02:30:45 pm', value: '%d/%m/%y %I:%M:%S %p' },
        { display: '26/03/2021 02:30:45 pm', value: '%d/%m/%Y %I:%M:%S %p' }
      ]
    }
  },
  get dateInputFormatOptions() {

    return [
      { display: '2021-03-26', format: 'YYYY-MM-DD' },
      { display: '26-03-2021', format: 'DD-MM-YYYY' },
      { display: '03-26-2021', format: 'MM-DD-YYYY' },
      { display: '26/03/2021', format: 'DD/MM/YYYY' },
      { display: '26/03/21', format: 'DD/MM/YY' },
      { display: '03/26/21', format: 'MM/DD/YY' },
      { display: '26-Mar-2021', format: 'DD-Mon-YYYY' },
      { display: '26-March-2021', format: 'DD-Month-YYYY' },
      { display: 'Fri, 26-March-2021', format: 'Dy, DD-Month-YYYY' },
      { display: '1477040445', format: null },
      { display: '2021-03-26T23:40:50Z', format: 'YYYY-MM-DD"T"HH24:MI:SS"Z"' },
      { display: '2021-03-2T23:40:50+05:30', format: 'YYYY-MM-DD"T"HH24:MI:SS OF' },
      { display: '2021-03-26T23:40:50 GMT', format: 'YYYY-MM-DD"T"HH24:MI:SS TZ' },
      { display: '2021-03-26 23:40:50', format: 'YYYY-MM-DD HH24:MI:SS' }
    ]

  },
  get defaultTimezone() {
    return 'UTC';
  },
  get integrationCategories() {
    return {
      // WEB: "Web & Mobile Analytics",
      // BI: "Business Intelligence",


      // DATACOLLECT: "Data Collector",
      // DATASCI: "Data Science",

      // APPS: "Applications"
      // EMAIL: "Email",
      // SQL: "SQL Tools",

      WORKSPACEING: "Workspaceing",
      ANALYTICS: "Analytics",
      FILE: "Cloud Storage",
      CRM: "CRM and Marketing",
      DB: "Databases",
      ECOM: "E-Commerce"


    }
  },
  get appFeatures() {
    return {
      connectors: "connectors",
      elements: "elements",
      explore: "explore",
      reports: "reports",
      alerts: "alerts",
      templates: "templates",
      nonMetricElements: "nonMetricElements",
      reorderTasks: "reorderTasks",
      webhooks: "webhooks",
      hourlyDataPull: "hourlyDataPull",
      minutelyDataPull: "minutelyDataPull",
      enableGetStarted: "enableGetStarted",
      showStorageInfo: "showStorageInfo",
      publishDB: "publishDB",
      collapseRows: "collapseRows"
    }
  },
  NOTIFICATION_ERRORS: {
    NO_DS: 'NO_DS'
  },
  useScientificFormatByDefaultInMetrics: false,

  type_to_type_desc_map: {
    TEXT: 'text',
    DATE: 'date',
    NUMERIC: 'num'
  },

  updateTypeToMessage: {
    'source_data_change': 'Updates from dataset',
    'dependency_update': 'Updates from ',
    'add_rule': 'Rule added',
    'edit_rule': 'Rule edited',
    'discard_rule': 'Rule discarded',
    'suspend_rule': 'Rule suspended',
    'tasks_reorder': 'Rules reordered',
    'draft_pipeline_submission': 'Pipeline modifications submitted',
    'run_pending_inbound_updates': 'Pipeline unsnoozed'

  },
  updateStatusToMessage: {
    'received': 'Received',
    'pending': 'Pending',
    'running': 'Consumed'
  }

};

const c = constants;
c['PERMISSIONS_PRECEDENCE'] = [c.PERMISSIONS.SUPER_ADMIN, c.PERMISSIONS.OWNER, c.PERMISSIONS.ADMIN,
c.PERMISSIONS.ANALYST, c.PERMISSIONS.GUEST];
c['statusesForProcessing'] = [c.dsStatus.unprocessed, c.dsStatus.processing, c.dsStatus.actionNeeded, c.fileStatus.actionNeeded,
  c.fileStatus.extracting, c.dsStatus.error, c.dsStatus.deleting, c.dsStatus.hasUnstructuredData, c.landingItemStatus.moving]