import * as _ from 'lodash-es';
import { populateTemplateCache } from './templateCache';
import { SplitFactory } from '@splitsoftware/splitio';
import { initializeSplitIo } from '../vueApp/src/mm-frontend/src/plugins/splitio'
import { initializeOnboarding } from '../vueApp/src/mm-frontend/src/utils/onboarding'

/**
 * @ngInject
 */
OnRun.$inject = ['$rootScope', 'config', '$state', '$location', 'c',
'$window', 'Auth', '$resource', '$q', '$timeout', 'utils', 'appHelperService', '$templateCache', 'Singularity', 'localService','UserWorkspace', '$stateParams', 'deploymentConsts', 'navigationService'];

export function OnRun($rootScope, config, $state, $location, c,
                      $window, Auth, $resource, $q, $timeout, utils, appHelperService, $templateCache, Singularity, localService,UserWorkspace, $stateParams, deploymentConsts, navigationService) {
  // make config available on $rootScope so that it is available on the templates
  var OAuthCodeCheck;
  var debouncedAuthCheckCb = utils.debounce(_tokenSuccessCB, 500, false);
  $rootScope.config = config;
  $rootScope.c = c;
  $rootScope.localService = localService;
  $rootScope.$state = $state;
  $rootScope.support = $window.openSupportApp;
  $rootScope.UserWorkspace = UserWorkspace;
  // temp code for demo mode
  $rootScope.demo_mode = {visible: false};
  $rootScope.dataViewSampleMode = {visible: false};
  $rootScope.searchbar = {visible: true};
  $rootScope.pipelineSnooze = {visible: false};
  $rootScope.globalPendingUpdates = {visible: false};
  $rootScope.replaceOptionInCombineDs = {visible: false};
  $rootScope.dataFlowMap = {visible: false};
  $rootScope.subscriptionPlan = {visible: false};
  $rootScope.bulkReplaceThresholdBox = { visible: false }
  $rootScope.bigquerySmartCombine = { visible: false }
  $rootScope.appHelperService = appHelperService;
  $rootScope.showPaymentError = false;
  $rootScope.disabledTasks = [];


  $rootScope.$watch(function () {
    return $location.search();
  }, function (newSearch, oldSearch) {
    $rootScope.$broadcast('queryStringUpdate');
    $rootScope.demo_mode.visible = !!$rootScope.$eval(newSearch['demo_mode']);
    $rootScope.dataViewSampleMode.visible = !!$rootScope.$eval(newSearch['samplemode']);
    if (_.has(newSearch, 'ds')) {
      appHelperService.navigateToDataset(newSearch['ds'])
    }
    if (_.has(newSearch, 'copypaste')) {
      localService.storage.copypaste = true;
    }
    if (_.has(newSearch, 'sftp')) {
      localService.storage.sftpEnabled = !!$rootScope.$eval(newSearch['sftp']);
    }
    if (_.has(newSearch, 'branchoutToAny')) {
      localService.storage.enableBranchoutToAnyDs = !!$rootScope.$eval(newSearch['branchoutToAny']);
    }
    if (_.has(newSearch, 'regexExtract')) {
      localService.storage.enableRegexInExtractText = !!$rootScope.$eval(newSearch['regexExtract']);
    }
    if (_.has(newSearch, 'disableBrowserWarnings')) {
      localService.storage.disableBrowserWarnings = !!$rootScope.$eval(newSearch['disableBrowserWarnings']);
    }
    let section = newSearch['onboarding']
    if (section == 'editMode'){
      var elements = document.getElementsByClassName('active-pipeline')
      if (elements.length){
        appHelperService.startOnBoarding('editMode');
      }
    }
   
    if(_.has(newSearch, 'disableOnBoarding')){
      var disableOnBoarding = !!$rootScope.$eval(newSearch['disableOnBoarding']);
      $location.search('disableOnBoarding', null);
      appHelperService.markOnBoardingSessionAsSeenGlobal(disableOnBoarding);
    }
  }, true);

  $rootScope.Utilities = {
    keysInObj: Object.keys
  };


  // change page title based on state
  $rootScope.$on('$stateChangeSuccess', function (event, toState) {
    $rootScope.pageTitle = '';

    if (toState.title) {
      $rootScope.pageTitle += toState.title;
      $rootScope.pageTitle += ' | ';
    }
    $rootScope.safeApply = function () {
      //force a digest cycle
      $timeout(function () {
      }, 0);
    };
    $rootScope.pageTitle += config.appTitle;
  });

  $rootScope.badTokenRetriesCount = 0;


  let unwatchUser = $rootScope.$watch('UserWorkspace', function (user) {
    if(user.workspace && user.workspace.id) {
      initializeSplit();
      unwatchUser();
      initializeOnboarding();
    }
  }, true);

  $rootScope.$watch('showPaymentError', function(showPaymentError){
    if(showPaymentError){
      setTimeout(() => {
        navigationService.openExpiredWorkspacePage();
      }, 1000);
    } else {
      if($window.location.hash.includes("expired")){
        navigationService.go_to_landing(true);
      }
    }
  })


  function initializeSplit() {
    // @ts-ignore
    initializeSplitIo(getSplitFeatureFlags); // Vue side
  }

  function getSplitFeatureFlags(client, attributes){
    
    // Retrieve feature flags defined
    const splitFeatureFlags = c.splitFeatureFlags;

    // Obtain flag names defined on Split.io
    const splitNames: string[] = Object.values(splitFeatureFlags);

    // Get treatments back for all splits defined
    const treatments: SplitIO.Treatments = client.getTreatments(splitNames, attributes);

    // SFTP
    if(treatments[splitFeatureFlags.SFTP] == "on"){
      localService.storage.setItem('sftpEnabled', true);
    }
    else{
      localService.storage.removeItem('sftpEnabled');
    }

    // Pipeline snooze feature
    $rootScope.pipelineSnooze.visible = (treatments[splitFeatureFlags.PIPELINE_SNOOZE] == "on");
    // Global pending updates feature
    $rootScope.globalPendingUpdates.visible = (treatments[splitFeatureFlags.GLOBAL_PENDING_UPDATES] == "on");
    // Replace option in combine ds
    $rootScope.replaceOptionInCombineDs.visible = (treatments[splitFeatureFlags.REPLACE_OPTION_IN_COMBINE_DS] == "on");
    // Plan section in settings feature
    $rootScope.subscriptionPlan.visible = (treatments[splitFeatureFlags.SUBSCRIPTION_PLAN] == "on");
    // Bulk Replace Threshold
    $rootScope.bulkReplaceThresholdBox.visible = (treatments[splitFeatureFlags.BULK_REPLACE_THRESHOLD] == "on");
    // BigQuery Smart Combine
    $rootScope.bigquerySmartCombine.visible = (treatments[splitFeatureFlags.BIGQUERY_SMART_COMBINE] == "on");
    
    // Minutely Data Pulls
    if (treatments[splitFeatureFlags.MINUTELY_DATA_PULL] == "off") {
      delete $rootScope.appFeatures.minutelyDataPull;
    }
    else {
      $rootScope.appFeatures.minutelyDataPull = splitFeatureFlags.MINUTELY_DATA_PULL;
    }

    // Hourly Data Pulls
    if (treatments[splitFeatureFlags.HOURLY_DATA_PULL] == "off") {
      delete $rootScope.appFeatures.hourlyDataPull;
    }
    else {
      $rootScope.appFeatures.hourlyDataPull = splitFeatureFlags.HOURLY_DATA_PULL;
    }

    // Publish DB
    if (treatments[splitFeatureFlags.PUBLISHDB] == "off") {
      delete $rootScope.appFeatures.publishDB;
    }
    else {
      $rootScope.appFeatures.publishDB = splitFeatureFlags.PUBLISHDB;
    }

    if (treatments[splitFeatureFlags.JOIN_BLOAT_WARNING] == "off") {
      delete $rootScope.appFeatures.joinBloatWarning;
    }
    else {
      $rootScope.appFeatures.joinBloatWarning = splitFeatureFlags.JOIN_BLOAT_WARNING;
    }

    if (treatments[splitFeatureFlags.DUPLICATE_VIEW_WITH_DS] == "off") {
      delete $rootScope.appFeatures.duplicateViewsWithDs;
    }
    else {
      $rootScope.appFeatures.duplicateViewsWithDs = splitFeatureFlags.DUPLICATE_VIEW_WITH_DS;
    }

    if (treatments[splitFeatureFlags.COMBINE_DS_COLUMN_MAPPING_FEATURES] == "off") {
      delete $rootScope.appFeatures.combineDsColumnMappingFeatures;
    }
    else {
      $rootScope.appFeatures.combineDsColumnMappingFeatures = splitFeatureFlags.COMBINE_DS_COLUMN_MAPPING_FEATURES;
    }
    if (treatments[splitFeatureFlags.COLLAPSE_DATAVIEWS] == "off") {
      delete $rootScope.appFeatures.collapseDataviews;
    }
    else {
      $rootScope.appFeatures.collapseDataviews = splitFeatureFlags.COLLAPSE_DATAVIEWS;
    }
    if (treatments[splitFeatureFlags.CURRENT_TIME_IN_DATE_DIFF] == "off") {
      delete $rootScope.appFeatures.currentTimeInDateDiff;
    }
    else {
      $rootScope.appFeatures.currentTimeInDateDiff = splitFeatureFlags.CURRENT_TIME_IN_DATE_DIFF;
    }
    
    // Templates
    if (treatments[splitFeatureFlags.CURRENT_TIME_IN_LABEL] == "off") {
      delete $rootScope.appFeatures.currentTimeInLabel;
    }
    else {
      $rootScope.appFeatures.currentTimeInLabel = splitFeatureFlags.CURRENT_TIME_IN_LABEL;
    }
    if (treatments[splitFeatureFlags.TEMPLATES] == "off") {
      delete $rootScope.appFeatures.templates;
    }
    else {
      $rootScope.appFeatures.templates = splitFeatureFlags.TEMPLATES;
    }

    // Alerts
    if (treatments[splitFeatureFlags.ALERTS] == "off") {
      delete $rootScope.appFeatures.alerts;
    }
    else {
      $rootScope.appFeatures.alerts = splitFeatureFlags.ALERTS;
    }


    // Sample Mode
    $rootScope.dataViewSampleMode.visible = (treatments[splitFeatureFlags.SAMPLE_MODE] == "on");

    // search bar functionality
    $rootScope.searchbar.visible = (treatments[splitFeatureFlags.SEARCH_BAR] == "on");
    // dataflow map
    $rootScope.dataFlowMap.visible = (treatments[splitFeatureFlags.DATA_FLOW_MAP] == "on");
    // Branch out to any
    if(treatments[splitFeatureFlags.BRANCH_OUT_TO_ANY] == "on"){
      localService.storage.setItem('enableBranchoutToAnyDs', true);
    }
    else{
      localService.storage.removeItem('enableBranchoutToAnyDs');
    }

    // Regex
    if(treatments[splitFeatureFlags.REGEX] == "on"){
      localService.storage.setItem('enableRegexInExtractText', true);
    }
    else{
      localService.storage.removeItem('enableRegexInExtractText');
    }

    // Collapse rows
    if (treatments[splitFeatureFlags.COLLAPSE_ROWS] == "off") {
      $rootScope.disabledTasks.push("collapseRows");
    }
  }

  // bad token retries counter
  let urlParams = $location.search();

  if (Object.keys(urlParams).length) {
    OAuthCodeCheck = Auth.on_check_token_success('OAuthRedirectionCheck', debouncedAuthCheckCb);
  }
  
  if (window['Highcharts']) {
    window['Highcharts'].setOptions({
      lang: {
        thousandsSep: ','
      }
    });
  }

  populateTemplateCache($templateCache, config);

  function _tokenSuccessCB() {
    Singularity.checkUrlParamsForAuthRedirect(urlParams).then(function (integration) {
      if (OAuthCodeCheck) {
        OAuthCodeCheck();
        OAuthCodeCheck = null;
      }

      if (!integration['isExport']) {
        window.location.href = `${window.location.origin}/#/workspaces/${$stateParams.workspaceId}/projects/${$stateParams.projectId}?datapull=` + integration['key'];
        Singularity.openDataPullDialogRequest()
      } else {
        if(integration['editId']){
            $window.location.href = '/#/dataviews/' + $stateParams.dataviewId + '?datapush=' + integration['key'] +
              '&editId=' + integration['editId'];
        }else{
          $window.location.href = '/#/dataviews/' + $stateParams.dataviewId + '?datapush=' + integration['key'];
        }
      }
    }, function(){
    })
  }
}

// Needed to ensure a consistent auth state across all the open tabs
// So when localStorage is changed from another tab, this event will be fired
// Event is fired on all tabs except the one where change was done

window.addEventListener('storage', (event) => {
  if (event.key === 'is_logged_in') window.location.reload()
})
