export const USER_EVENTS = {
  ACTION_BAR: {
    COLUMN_BROWSER: {
      ACTION: {
        APPLY: 'ACTION_BAR_COLUMN_BROWSER_ACTION_APPLY',
        CONVERT_COLUMN_TYPE: 'ACTION_BAR_COLUMN_BROWSER_ACTION_CONVERT_COLUMN_TYPE',
        DISCARD: 'ACTION_BAR_COLUMN_BROWSER_ACTION_DISCARD',
        HIDE: 'ACTION_BAR_COLUMN_BROWSER_ACTION_HIDE',
        OPEN_IN_EXPLORE_COLUMNS: 'ACTION_BAR_COLUMN_BROWSER_ACTION_OPEN_IN_EXPLORE_COLUMNS',
        REMOVE: 'ACTION_BAR_COLUMN_BROWSER_ACTION_REMOVE',
        RENAME: 'ACTION_BAR_COLUMN_BROWSER_ACTION_RENAME',
        REORDER: 'ACTION_BAR_COLUMN_BROWSER_ACTION_REORDER',
        REPLACE: 'ACTION_BAR_COLUMN_BROWSER_ACTION_REPLACE',
        SHOW: 'ACTION_BAR_COLUMN_BROWSER_ACTION_SHOW',
        SHOW_ONLY_THESE: 'ACTION_BAR_COLUMN_BROWSER_ACTION_SHOW_ONLY_THESE'
      },
      COLUMN_SELECT: 'ACTION_BAR_COLUMN_BROWSER_COLUMN_SELECT',
      TOGGLE: 'ACTION_BAR_COLUMN_BROWSER_TOGGLE'
    },
    COMBINE_ITEM_CLICK: 'ACTION_BAR_COMBINE_ITEM_CLICK',
    EXPORT: {
      ALREADY_EXISTING_DATABASE_CLICK: 'ACTION_BAR_EXPORT_ALREADY_EXISTING_DATABASE_CLICK',
      BIGQUERY: {
        APPLY: 'ACTION_BAR_EXPORT_BIG_QUERY_APPLY',
        CANCEL: 'ACTION_BAR_EXPORT_BIG_QUERY_CANCEL',
        DELETE_CONNECTION: 'ACTION_BAR_EXPORT_BIG_QUERY_DELETE_CONNECTION',
        KEEP_AT_END_TOGGLE: 'ACTION_BAR_EXPORT_BIG_QUERY_KEEP_AT_END_TOGGLE',
        SELECT_DATA_UPDATE_METHOD: 'ACTION_BAR_EXPORT_BIG_QUERY_SELECT_DATA_UPDATE_METHOD',
        SELECT_PARTITION_COLUMN: 'ACTION_BAR_EXPORT_BIG_QUERY_SELECT_PARTITION_COLUMN',
        SELECT_PARTITION_GRANULARITY: 'ACTION_BAR_EXPORT_BIG_QUERY_SELECT_PARTITION_GRANULARITY',
        SELECT_PROJECT: 'ACTION_BAR_EXPORT_BIG_QUERY_SELECT_PROJECT',
        SELECT_REFERENCE_COLUMNS: 'ACTION_BAR_EXPORT_BIG_QUERY_SELECT_REFERENCE_COLUMNS',
        TABLE_NAME_INPUT: 'ACTION_BAR_EXPORT_BIG_QUERY_TABLE_NAME_INPUT',
        TABLE_PARTITION_TOGGLE: 'ACTION_BAR_EXPORT_BIG_QUERY_TABLE_PARTITION_TOGGLE'
      },
      DATABASE_SELECT: 'ACTION_BAR_EXPORT_DATABASE_SELECT',
      DOWNLOAD_CSV_CLICK: 'ACTION_BAR_EXPORT_DOWNLOAD_CSV_CLICK',
      DOWNLOAD_XLSX_CLICK: 'ACTION_BAR_EXPORT_DOWNLOAD_XLSX_CLICK',
      EXPORT_TO_DATABASE_CLICK: 'ACTION_BAR_EXPORT_EXPORT_TO_DATABASE_CLICK',
      EXPORT_VIA_SFTP_CLICK: 'ACTION_BAR_EXPORT_EXPORT_VIA_SFTP_CLICK',
      LIVE_LINK_CLICK: 'ACTION_BAR_EXPORT_LIVE_LINK_CLICK',
      MAMMOTH_MANAGED_DATABASE_CLICK: 'ACTION_BAR_EXPORT_MAMMOTH_MANAGED_DATABASE_CLICK',
      PUBLISH_AS_REPORT_CLICK: 'ACTION_BAR_EXPORT_PUBLISH_AS_REPORT_CLICK',
      SAVE_TO_DATASET: 'ACTION_BAR_EXPORT_SAVE_TO_DATASET_CLICK',
      SET_TABLE_NAME: 'ACTION_BAR_EXPORT_SET_TABLE_NAME',
      SHOW_MAMMOTH_MANAGED_CREDENTIALS: 'ACTION_BAR_EXPORT_SHOW_MAMMOTH_MANAGED_CREDENTIALS'
    },
    TOGGLE_PIPELINE: 'ACTION_BAR_TOGGLE_PIPELINE',
    TRANSFORM_ITEM_CLICK: 'ACTION_BAR_TRANSFORM_ITEM_CLICK'
  },
  DATA_ADDITION: {
    BACK_TO_MAIN: 'DATA_ADDITION_BACK_TO_MAIN',
    FETCH_FROM_URL: {
      OPEN: 'DATA_ADDITION_FETCH_FROM_URL_OPEN',
      SUBMIT: 'DATA_ADDITION_FETCH_FROM_URL_SUBMIT'
    },
    FILE_UPLOAD: {
      MORE_DATA: {
        SUBMIT: 'DATA_ADDITION_FILE_UPLOAD_MORE_DATA_SUBMIT'
      },
      OPEN: 'DATA_ADDITION_FILE_UPLOAD_OPEN',
      SUBMIT: 'DATA_ADDITION_FILE_UPLOAD_SUBMIT'
    },
    THIRD_PARTY: {
      NEW_CONNECTION_OPTION_SELECTED: 'DATA_ADDITION_THIRD_PARTY_NEW_CONNECTION_OPTION_SELECTED',
      NEW_CONNECTION_SUBMIT: 'DATA_ADDITION_THIRD_PARTY_NEW_CONNECTION_SUBMIT',
      OPEN: 'DATA_ADDITION_THIRD_PARTY_OPEN',
      OPEN_CONNECTION: 'DATA_ADDITION_THIRD_PARTY_OPEN_CONNECTION',
      OPEN_NEW_CONNECTION: 'DATA_ADDITION_THIRD_PARTY_OPEN_NEW_CONNECTION',
      OPEN_PROFILE: 'DATA_ADDITION_THIRD_PARTY_OPEN_PROFILE',
      OPEN_TABLE: 'DATA_ADDITION_THIRD_PARTY_OPEN_TABLE',
      RUN_QUERY: 'DATA_ADDITION_THIRD_PARTY_RUN_QUERY',
      SELECT: 'DATA_ADDITION_THIRD_PARTY_SELECT',
      STEP_CHANGE: 'DATA_ADDITION_THIRD_PARTY_STEP_CHANGE',
      SUBMIT: 'DATA_ADDITION_THIRD_PARTY_SUBMIT'
    },
    WEBHOOK: {
      OPEN: 'DATA_ADDITION_WEBHOOK_OPEN',
      SUBMIT: 'DATA_ADDITION_WEBHOOK_SUBMIT'
    }
  },
  DATA_EDITOR: {
    FOOTER: {
      WARNING_CLICK: 'DATA_EDITOR_FOOTER_WARNING_CLICK'
    }
  },
  DATA_EDITOR_GRID: {
    RENAME_COLUMN: 'RENAME_COLUMN',
    REORDER_COLUMNS: 'REORDER_COLUMNS',
    RESIZE_COLUMNS: 'RESIZE_COLUMNS'
  },
  DATA_LIBRARY: {
    HEADER: {
      SHARE: 'DATA_LIBRARY_HEADER_SHARE'
    },
    SIDEBAR: {
      ADD_NEW_PROJECT: 'DATA_LIBRARY_SIDEBAR_ADD_NEW_PROJECT',
      OPEN: 'DATA_LIBRARY_SIDEBAR_OPEN',
      SELECT_WORKSPACE: 'DATA_LIBRARY_SIDEBAR_SELECT_WORKSPACE',
      SETTINGS: 'DATA_LIBRARY_SIDEBAR_WORKSPACE_SETTINGS'
    },
    VIEWS_LIST: {
      DATA_SYNC_ICON_CLICK: 'DATA_LIBRARY_VIEWS_LIST_DATA_SYNC_ICON_CLICK',
      DELETE_VIEWS: 'DATA_LIBRARY_VIEWS_LIST_DELETE_VIEWS',
      OPEN_MENU: 'DATA_LIBRARY_VIEWS_LIST_OPEN_MENU',
      OPEN_VIEW: 'DATA_LIBRARY_VIEWS_LIST_OPEN_VIEW',
      TOGGLE_DATA_SYNC: 'DATA_LIBRARY_VIEWS_LIST_TOGGLE_DATA_SYNC',
      WARNING_ICON_CLICK: 'DATA_LIBRARY_VIEWS_LIST_WARNING_ICON_CLICK'
    }
  },
  DATAFLOW_STATUS: {
    ACTION_REQUIRED: {
      DATA_UPDATES: {
        OPEN_RESOURCE: 'DATAFLOW_STATUS_ACTION_REQUIRED_DATA_UPDATES_OPEN_RESOURCE',
        UPDATE_DATA: 'DATAFLOW_STATUS_ACTION_REQUIRED_DATA_UPDATES_UPDATE_DATA'
      },
      PIPELINE_CHANGES: {
        OPEN_RESOURCE: 'DATAFLOW_STATUS_ACTION_REQUIRED_PIPELINE_CHANGES_OPEN_RESOURCE',
        RUN_PIPELINES: 'DATAFLOW_STATUS_ACTION_REQUIRED_PIPELINE_CHANGES_RUN_PIPELINES'
      }
    },
    ACTIVE_PIPELINES: {
      OPEN_RESOURCE: 'DATAFLOW_STATUS_ACTIVE_PIPELINES_OPEN_RESOURCE'
    },
    NON_SYNC_TASKS: {
      OPEN_RESOURCE: 'DATAFLOW_STATUS_NON_SYNC_TASKS_OPEN_RESOURCE'
    },
    TAB_SELECT: 'DATAFLOW_STATUS_TAB_SELECT'
  },
  FOLDER_BREADCRUMB: {
    OPEN: 'FOLDER_BREADCRUMB_OPEN'
  },
  LINKED_IN_OUT: {
    OPEN_VIEW: 'LINKED_IN_OUT_OPEN_VIEW'
  },
  LOGIN: 'LOGIN',
  LOGIN_OTP: 'LOGIN_OTP',
  NAVBAR: {
    DATAFLOW_STATUS: {
      OPEN: 'NAVBAR_DATAFLOW_STATUS_OPEN'
    },
    HELP: {
      DOCS: 'NAVBAR_HELP_DOCS',
      OPEN: 'NAVBAR_HELP_OPEN',
      SUPPORT: 'NAVBAR_HELP_SUPPORT'
    },
    LOGO: {
      CLICK: 'NAVBAR_LOGO_CLICK'
    },
    NOTIFICATIONS: {
      DELETE: 'NAVBAR_NOTIFICATIONS_DELETE',
      DELETE_READS: 'NAVBAR_NOTIFICATIONS_DELETE_READS',
      OPEN: 'NAVBAR_NOTIFICATIONS_OPEN',
      MARK_ALL_READ: 'NAVBAR_NOTIFICATIONS_MARK_ALL_READ',
      READ: 'NAVBAR_NOTIFICATIONS_READ',
      UNREAD: 'NAVBAR_NOTIFICATIONS_UNREAD'
    },
    PROFILE: {
      CHANGE_WORKSPACE: 'NAVBAR_PROFILE_CHANGE_WORKSPACE',
      LOGOUT: 'NAVBAR_PROFILE_LOGOUT',
      OPEN: 'NAVBAR_PROFILE_OPEN',
      SETTINGS: 'NAVBAR_PROFILE_SETTINGS'
    },
    RELEASES: {
      OPEN: 'NAVBAR_RELEASES_OPEN'
    },
    TABS: {
      ADD_NEW: 'NAVBAR_TABS_ADD_NEW',
      OPEN_VIEW: 'NAVBAR_TABS_OPEN_VIEW'
    }
  },
  ONBOARDING: {
    ORGANISATION: {
      CREATE: 'ONBOARDING_ORGANISATION_CREATE'
    },
    DATA_ADDITION: {
      FILE_UPLOAD: {
        OPEN: 'DATA_ADDITION_FILE_UPLOAD_OPEN'
      },
      THIRD_PARTY: {
        OPEN: 'DATA_ADDITION_THIRD_PARTY_OPEN'
      }
    }
  },
  PIPELINE: {
    DATAFLOW_SETTINGS: {
      DATA_SYNC_OPTIONS: {
        OPEN: 'PIPELINE_DATAFLOW_SETTINGS_DATA_SYNC_OPTIONS_OPEN',
        SUBMIT: 'PIPELINE_DATAFLOW_SETTINGS_DATA_SYNC_OPTIONS_SUBMIT',
        TAB_SELECT: 'PIPELINE_DATAFLOW_SETTINGS_DATA_SYNC_OPTIONS_TAB_SELECT',
        TOGGLE_DATA_SYNC: 'PIPELINE_DATAFLOW_SETTINGS_DATA_SYNC_OPTIONS_TOGGLE_DATA_SYNC'
      },
      TOGGLE: 'PIPELINE_DATAFLOW_SETTINGS_TOGGLE',
      TOGGLE_AUTO_RUN: 'PIPELINE_DATAFLOW_SETTINGS_TOGGLE_AUTO_RUN'
    },
    TOGGLE_PREVIEW: 'PIPELINE_TOGGLE_PREVIEW'
  },
  PROJECT_OPTIONS: {
    SELECT: 'PROJECT_OPTIONS_SELECT',
    TOGGLE: 'PROJECT_OPTIONS_TOGGLE'
  },
  REPORTS: {
    ACTION_BAR: {
      DOWNLOAD_DATA: 'REPORTS_ACTION_BAR_DOWNLOAD_DATA'
    },
    EXPLORE_SECTION: {
      TOGGLE: 'REPORTS_EXPLORE_SECTION_TOGGLE'
    }
  },
  SETTINGS: {
    API_TOKENS: {
      CREATE: 'SETTINGS_API_TOKENS_CREATE'
    },
    BILLING: {
      CHANGE_ADDRESS: 'SETTINGS_BILLING_CHANGE_ADDRESS',
      CHANGE_METHOD: 'SETTINGS_BILLING_CHANGE_METHOD',
      VIEW_INVOICES: 'SETTINGS_BILLING_VIEW_INVOICES'
    },
    CONNECTORS: {
      ADD: 'SETTINGS_CONNECTORS_ADD',
      CANCEL: 'SETTINGS_CONNECTORS_CANCEL',
      CHECKOUT: 'SETTINGS_CONNECTORS_CHECKOUT',
      OPEN: 'SETTINGS_CONNECTORS_OPEN',
      REMOVE: {
        CANCEL: 'SETTINGS_CONNECTORS_REMOVE_CANCEL',
        CLICK: 'SETTINGS_CONNECTORS_REMOVE_CLICK',
        REMOVE: 'SETTINGS_CONNECTORS_REMOVE_REMOVE'
      },
      SEARCH: 'SETTINGS_CONNECTORS_SEARCH',
      SUMMARY: {
        BACK: 'SETTINGS_CONNECTORS_SUMMARY_BACK',
        CONFIRM: 'SETTINGS_CONNECTORS_SUMMARY_CONFIRM',
        CLOSE: 'SETTINGS_CONNECTORS_SUMMARY_CLOSE',
        DELETE: 'SETTINGS_CONNECTORS_SUMMARY_DELETE'
      }
    },
    PLAN: {
      CHANGE: 'SETTINGS_PLAN_CHANGE'
    },
    PROJECT: {
      NEW: 'SETTINGS_PROJECT_NEW',
      OPEN_MENU: 'SETTINGS_PROJECT_OPEN_MENU'
    },
    SIDEBAR: {
      NAVIGATE: 'SETTINGS_SIDEBAR_NAVIGATE'
    },
    STORAGE: {
      OPEN: 'SETTINGS_STORAGE_OPEN'
    },
    USERS: {
      ADD_TO_PROJECT: 'SETTINGS_USERS_ADD_TO_PROJECT',
      INVITE: 'SETTINGS_USERS_INVITE',
      MANAGE_PROJECT_OPEN_MODAL: 'SETTINGS_USERS_MANAGE_PROJECT_OPEN_MODAL',
      NEW_ROLE_DROPDOWN_SELECT: 'SETTINGS_USERS_NEW_ROLE_DROPDOWN_SELECT',
      OPEN: 'SETTINGS_USERS_OPEN',
      REMOVE_FROM_PROJECT: 'SETTINGS_USERS_REMOVE_FROM_PROJECT',
      ROLE_DROPDOWN_SELECT: 'SETTINGS_USERS_ROLE_DROPDOWN_SELECT'
    }
  },
  VIEW_OPTIONS: {
    APPLY_TEMPLATE: 'VIEW_OPTIONS_APPLY_TEMPLATE',
    DELETE: 'VIEW_OPTIONS_DELETE',
    DUPLICATE: 'VIEW_OPTIONS_DUPLICATE',
    RENAME: 'VIEW_OPTIONS_RENAME',
    RESET: 'VIEW_OPTIONS_RESET',
    SAVE_AS_TEMPLATE: 'VIEW_OPTIONS_SAVE_AS_TEMPLATE',
    TOGGLE_DATA_SYNC: 'VIEW_OPTIONS_TOGGLE_DATA_SYNC'
  }
}

export const USER_EVENT_CONTENT = {
  [USER_EVENTS.ACTION_BAR.TOGGLE_PIPELINE]: {
    event_name: 'actionBarTogglePipeline',
    event_context: { eventOrigin: 'action_bar', section: 'pipeline' }
  },
  [USER_EVENTS.ACTION_BAR.TRANSFORM_ITEM_CLICK]: {
    event_name: 'actionBarTransformItemClick',
    event_context: { eventOrigin: 'action_bar', section: 'transform' }
  },
  [USER_EVENTS.ACTION_BAR.COMBINE_ITEM_CLICK]: {
    event_name: 'actionBarCombineItemClick',
    event_context: { eventOrigin: 'action_bar', section: 'combine' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.PUBLISH_AS_REPORT_CLICK]: {
    event_name: 'actionBarExportPublishAsReport',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.LIVE_LINK_CLICK]: {
    event_name: 'actionBarExportLiveLinkClick',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.DOWNLOAD_CSV_CLICK]: {
    event_name: 'actionBarExportDownloadCsvClick',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.DOWNLOAD_XLSX_CLICK]: {
    event_name: 'actionBarExportDownloadXlsxClick',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.EXPORT_TO_DATABASE_CLICK]: {
    event_name: 'actionBarExportToDatabase',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.ALREADY_EXISTING_DATABASE_CLICK]: {
    event_name: 'actionBarExportAlreadyExistingDatabaseClick',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.MAMMOTH_MANAGED_DATABASE_CLICK]: {
    event_name: 'actionBarExportMammothManagedDatabaseClick',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.DATABASE_SELECT]: {
    event_name: 'actionBarExportDatabaseSelect',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.SET_TABLE_NAME]: {
    event_name: 'actionBarExportSetTableName',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.SHOW_MAMMOTH_MANAGED_CREDENTIALS]: {
    event_name: 'actionBarExportShowMammothManagedCredentials',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.EXPORT_VIA_SFTP_CLICK]: {
    event_name: 'actionBarExportSftp',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.SAVE_AS_DATASET_CLICK]: {
    event_name: 'actionBarSaveAsDataset',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.SAVE_TO_DATASET]: {
    event_name: 'actionBarSaveToDataset',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.APPLY]: {
    event_name: 'actionBarExportBigQueryApply',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.CANCEL]: {
    event_name: 'actionBarExportBigQueryCancel',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.DELETE_CONNECTION]: {
    event_name: 'actionBarExportBigQueryDeleteConnection',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.KEEP_AT_END_TOGGLE]: {
    event_name: 'actionBarExportBigQueryKeepAtEndToggle',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_DATA_UPDATE_METHOD]: {
    event_name: 'actionBarExportBigQuerySelectDataUpdateMethod',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_PARTITION_COLUMN]: {
    event_name: 'actionBarExportBigQuerySelectPartitionColumn',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_PARTITION_GRANULARITY]: {
    event_name: 'actionBarExportBigQuerySelectPartitionGranularity',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_PROJECT]: {
    event_name: 'actionBarExportBigQuerySelectProject',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.SELECT_REFERENCE_COLUMNS]: {
    event_name: 'actionBarExportBigQuerySelectReferenceColumns',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.TABLE_NAME_INPUT]: {
    event_name: 'actionBarExportBigQueryTableNameInput',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.EXPORT.BIGQUERY.TABLE_PARTITION_TOGGLE]: {
    event_name: 'actionBarExportBigQueryTablePartitionToggle',
    event_context: { eventOrigin: 'action_bar', section: 'export' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.TOGGLE]: {
    event_name: 'actionBarColumnBrowserToggle',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.COLUMN_SELECT]: {
    event_name: 'actionBarColumnBrowserColumnSelect',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.SHOW]: {
    event_name: 'actionBarColumnBrowserActionShow',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.HIDE]: {
    event_name: 'actionBarColumnBrowserActionHide',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.SHOW_ONLY_THESE]: {
    event_name: 'actionBarColumnBrowserActionShowOnlyThese',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.REORDER]: {
    event_name: 'actionBarColumnBrowserActionReorder',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.RENAME]: {
    event_name: 'actionBarColumnBrowserActionRename',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.APPLY]: {
    event_name: 'actionBarColumnBrowserActionApply',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.DISCARD]: {
    event_name: 'actionBarColumnBrowserActionDiscard',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.REMOVE]: {
    event_name: 'actionBarColumnBrowserActionRemove',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.REPLACE]: {
    event_name: 'actionBarColumnBrowserActionReplace',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.CONVERT_COLUMN_TYPE]: {
    event_name: 'actionBarColumnBrowserActionConvertColumnType',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.ACTION_BAR.COLUMN_BROWSER.ACTION.OPEN_IN_EXPLORE_COLUMNS]: {
    event_name: 'actionBarColumnBrowserActionOpenInExploreColumns',
    event_context: { eventOrigin: 'action_bar', section: 'columnBrowser' }
  },
  [USER_EVENTS.DATA_ADDITION.BACK_TO_MAIN]: {
    event_name: 'addDataBackToMainMenu',
    event_context: { eventOrigin: 'addDataModal', section: 'backToMainButton' }
  },
  [USER_EVENTS.DATA_ADDITION.FETCH_FROM_URL.OPEN]: {
    event_name: 'addDataOpen',
    event_context: { eventOrigin: 'addDataModal', section: 'fetchFromUrl' }
  },
  [USER_EVENTS.DATA_ADDITION.FETCH_FROM_URL.SUBMIT]: {
    event_name: 'addData',
    event_context: { eventOrigin: 'addDataModal', section: 'fetchFromUrl' }
  },
  [USER_EVENTS.DATA_ADDITION.FILE_UPLOAD.MORE_DATA.SUBMIT]: {
    event_name: 'addMoreData',
    event_context: { eventOrigin: 'dataLibrary', section: 'addMoreDataDialog' }
  },
  [USER_EVENTS.DATA_ADDITION.FILE_UPLOAD.OPEN]: {
    event_name: 'addDataOpen',
    event_context: { eventOrigin: 'addDataModal', section: 'fileUpload' }
  },
  [USER_EVENTS.DATA_ADDITION.FILE_UPLOAD.SUBMIT]: {
    event_name: 'addData',
    event_context: { eventOrigin: 'addDataModal', section: 'fileUpload' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.NEW_CONNECTION_OPTION_SELECTED]: {
    event_name: 'addDataNewConnectionOptionSelected',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.NEW_CONNECTION_SUBMIT]: {
    event_name: 'addDataNewConnectionSubmit',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN]: {
    event_name: 'addDataOpen',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_CONNECTION]: {
    event_name: 'addDataOpenConnection',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_NEW_CONNECTION]: {
    event_name: 'addDataOpenNewConnection',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_PROFILE]: {
    event_name: 'addDataOpenProfile',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.OPEN_TABLE]: {
    event_name: 'addDataOpenTable',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.RUN_QUERY]: {
    event_name: 'addDataRunQuery',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.SELECT]: {
    event_name: 'addDataSelectConnector',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.STEP_CHANGE]: {
    event_name: 'addDataStepChange',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.THIRD_PARTY.SUBMIT]: {
    event_name: 'addData',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.DATA_ADDITION.WEBHOOK.OPEN]: {
    event_name: 'addDataOpen',
    event_context: { eventOrigin: 'addDataModal', section: 'webhook' }
  },
  [USER_EVENTS.DATA_ADDITION.WEBHOOK.SUBMIT]: {
    event_name: 'addData',
    event_context: { eventOrigin: 'addDataModal', section: 'webhook' }
  },
  [USER_EVENTS.DATA_EDITOR.FOOTER.WARNING_CLICK]: {
    event_name: 'warningClick',
    event_context: { eventOrigin: 'dataEditor', section: 'footer' }
  },
  [USER_EVENTS.DATA_EDITOR_GRID.RENAME_COLUMN]: {
    event_name: 'dataEditorGridRenameColumn',
    event_context: { eventOrigin: 'data_editor_grid', section: 'grid' }
  },
  [USER_EVENTS.DATA_EDITOR_GRID.REORDER_COLUMNS]: {
    event_name: 'dataEditorGridReorderColumns',
    event_context: { eventOrigin: 'data_editor_grid', section: 'grid' }
  },
  [USER_EVENTS.DATA_EDITOR_GRID.RESIZE_COLUMNS]: {
    event_name: 'dataEditorGridResizeColumns',
    event_context: { eventOrigin: 'data_editor_grid', section: 'grid' }
  },
  [USER_EVENTS.DATA_LIBRARY.HEADER.SHARE]: {
    event_name: 'shareProject',
    event_context: { eventOrigin: 'dataLibrary', section: 'header' }
  },
  [USER_EVENTS.DATA_LIBRARY.SIDEBAR.ADD_NEW_PROJECT]: {
    event_name: 'addNewProject',
    event_context: { eventOrigin: 'dataLibrary', section: 'sidebar' }
  },
  [USER_EVENTS.DATA_LIBRARY.SIDEBAR.OPEN]: {
    event_name: 'sidebarItemClick',
    event_context: { eventOrigin: 'dataLibrary', section: 'sidebar' }
  },
  [USER_EVENTS.DATA_LIBRARY.SIDEBAR.SELECT_WORKSPACE]: {
    event_name: 'toggleWorkspaceMenu',
    event_context: { eventOrigin: 'dataLibrary', section: 'sidebar' }
  },
  [USER_EVENTS.DATA_LIBRARY.SIDEBAR.SETTINGS]: {
    event_name: 'openWorkspaceSettings',
    event_context: { eventOrigin: 'dataLibrary', section: 'sidebar' }
  },
  [USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.DATA_SYNC_ICON_CLICK]: {
    event_name: 'dataSyncIconClick',
    event_context: { eventOrigin: 'dataLibrary', section: 'viewsList' }
  },
  [USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.DELETE_VIEWS]: {
    event_name: 'viewDelete',
    event_context: { eventOrigin: 'dataLibrary', section: 'viewsList' }
  },
  [USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.OPEN_MENU]: {
    event_name: 'openViewMenu',
    event_context: { eventOrigin: 'dataLibrary', section: 'viewsList' }
  },
  [USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.OPEN_VIEW]: {
    event_name: 'openView',
    event_context: { eventOrigin: 'dataLibrary', section: 'viewsList' }
  },
  [USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.TOGGLE_DATA_SYNC]: {
    event_name: 'toggleDataSync',
    event_context: { eventOrigin: 'dataLibrary', section: 'viewsList' }
  },
  [USER_EVENTS.DATA_LIBRARY.VIEWS_LIST.WARNING_ICON_CLICK]: {
    event_name: 'warningIconClick',
    event_context: { eventOrigin: 'dataLibrary', section: 'viewsList' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.DATA_UPDATES.OPEN_RESOURCE]: {
    event_name: 'openResource',
    event_context: { eventOrigin: 'dataflowStatus', section: 'dataUpdates' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.DATA_UPDATES.UPDATE_DATA]: {
    event_name: 'updateData',
    event_context: { eventOrigin: 'dataflowStatus', section: 'dataUpdates' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.PIPELINE_CHANGES.OPEN_RESOURCE]: {
    event_name: 'openResource',
    event_context: { eventOrigin: 'dataflowStatus', section: 'pipelineChanges' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.ACTION_REQUIRED.PIPELINE_CHANGES.RUN_PIPELINES]: {
    event_name: 'runPipelines',
    event_context: { eventOrigin: 'dataflowStatus', section: 'pipelineChanges' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.ACTIVE_PIPELINES.OPEN_RESOURCE]: {
    event_name: 'openResource',
    event_context: { eventOrigin: 'dataflowStatus', section: 'activePipelines' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.NON_SYNC_TASKS.OPEN_RESOURCE]: {
    event_name: 'openResource',
    event_context: { eventOrigin: 'dataflowStatus', section: 'nonSyncTasks' }
  },
  [USER_EVENTS.DATAFLOW_STATUS.TAB_SELECT]: {
    event_name: 'selectTab',
    event_context: { eventOrigin: 'dataflowStatus', section: 'tabs' }
  },
  [USER_EVENTS.FOLDER_BREADCRUMB.OPEN]: {
    event_name: 'goToFolder',
    event_context: { eventOrigin: 'folderBreadcrumb', section: 'breadcrumb' }
  },
  [USER_EVENTS.LINKED_IN_OUT.OPEN_VIEW]: {
    event_name: 'openView',
    event_context: { eventOrigin: 'dataLibrary', section: 'linkedInOut' }
  },
  [USER_EVENTS.LOGIN]: {
    event_name: 'userLoggedIn',
    event_context: { eventOrigin: 'login', section: 'username/password' }
  },
  [USER_EVENTS.LOGIN_OTP]: {
    event_name: 'userLoggedIn',
    event_context: { eventOrigin: 'login', section: 'OTP' }
  },
  [USER_EVENTS.NAVBAR.DATAFLOW_STATUS.OPEN]: {
    event_name: 'openDataflowStatus',
    event_context: { eventOrigin: 'navbar', section: 'dataflowStatus' }
  },
  [USER_EVENTS.NAVBAR.HELP.DOCS]: {
    event_name: 'navbarHelpDocs',
    event_context: { eventOrigin: 'navbar', section: 'help' }
  },
  [USER_EVENTS.NAVBAR.HELP.OPEN]: {
    event_name: 'navbarHelpOpen',
    event_context: { eventOrigin: 'navbar', section: 'help' }
  },
  [USER_EVENTS.NAVBAR.HELP.SUPPORT]: {
    event_name: 'navbarHelpSupport',
    event_context: { eventOrigin: 'navbar', section: 'help' }
  },
  [USER_EVENTS.NAVBAR.LOGO.CLICK]: {
    event_name: 'navbarLogoClick',
    event_context: { eventOrigin: 'navbar', section: 'logo' }
  },
  [USER_EVENTS.NAVBAR.NOTIFICATIONS.DELETE]: {
    event_name: 'navbarNotificationsDelete',
    event_context: { eventOrigin: 'navbar', section: 'notifications' }
  },
  [USER_EVENTS.NAVBAR.NOTIFICATIONS.DELETE_READS]: {
    event_name: 'navbarNotificationsDeleteReads',
    event_context: { eventOrigin: 'navbar', section: 'notifications' }
  },
  [USER_EVENTS.NAVBAR.NOTIFICATIONS.OPEN]: {
    event_name: 'navbarNotificationsOpen',
    event_context: { eventOrigin: 'navbar', section: 'notifications' }
  },
  [USER_EVENTS.NAVBAR.NOTIFICATIONS.MARK_ALL_READ]: {
    event_name: 'navbarNotificationsMarkAllRead',
    event_context: { eventOrigin: 'navbar', section: 'notifications' }
  },
  [USER_EVENTS.NAVBAR.NOTIFICATIONS.READ]: {
    event_name: 'navbarNotificationsRead',
    event_context: { eventOrigin: 'navbar', section: 'notifications' }
  },
  [USER_EVENTS.NAVBAR.NOTIFICATIONS.UNREAD]: {
    event_name: 'navbarNotificationsUnread',
    event_context: { eventOrigin: 'navbar', section: 'notifications' }
  },
  [USER_EVENTS.NAVBAR.PROFILE.CHANGE_WORKSPACE]: {
    event_name: 'changeWorkspace',
    event_context: { eventOrigin: 'navbar', section: 'profile' }
  },
  [USER_EVENTS.NAVBAR.PROFILE.LOGOUT]: {
    event_name: 'userLoggedOut',
    event_context: { eventOrigin: 'navbar', section: 'profile' }
  },
  [USER_EVENTS.NAVBAR.PROFILE.OPEN]: {
    event_name: 'navbarProfileOpen',
    event_context: { eventOrigin: 'navbar', section: 'profile' }
  },
  [USER_EVENTS.NAVBAR.PROFILE.SETTINGS]: {
    event_name: 'navbarProfileSettings',
    event_context: { eventOrigin: 'navbar', section: 'profile' }
  },
  [USER_EVENTS.NAVBAR.RELEASES.OPEN]: {
    event_name: 'navbarReleasesOpen',
    event_context: { eventOrigin: 'navbar', section: 'releases' }
  },
  [USER_EVENTS.NAVBAR.TABS.ADD_NEW]: {
    event_name: 'datasetAddView',
    event_context: { eventOrigin: 'navbar', section: 'tabs' }
  },
  [USER_EVENTS.NAVBAR.TABS.OPEN_VIEW]: {
    event_name: 'openView',
    event_context: { eventOrigin: 'navbar', section: 'tabs' }
  },
  [USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.OPEN]: {
    event_name: 'openDataSyncOptions',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.ONBOARDING.DATA_ADDITION.FILE_UPLOAD.OPEN]: {
    event_name: 'onboardingDataAdditionFileUploadOpen',
    event_context: { eventOrigin: 'addDataModal', section: 'fileUpload' }
  },
  [USER_EVENTS.ONBOARDING.DATA_ADDITION.THIRD_PARTY.OPEN]: {
    event_name: 'onboardingDataAdditionFileUploadOpen',
    event_context: { eventOrigin: 'addDataModal', section: 'thirdParty' }
  },
  [USER_EVENTS.ONBOARDING.ORGANISATION.CREATE]: {
    event_name: 'createOrganisation',
    event_context: { eventOrigin: 'onboarding', section: 'organisation' }
  },
  [USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.SUBMIT]: {
    event_name: 'submitDataSyncOptions',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.TAB_SELECT]: {
    event_name: 'selectTab',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.DATA_SYNC_OPTIONS.TOGGLE_DATA_SYNC]: {
    event_name: 'toggleDataSync',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.TOGGLE]: {
    event_name: 'toggleDataflowSettings',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.PIPELINE.DATAFLOW_SETTINGS.TOGGLE_AUTO_RUN]: {
    event_name: 'toggleAutoRun',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.PIPELINE.TOGGLE_PREVIEW]: {
    event_name: 'enteringStepPreviewMode',
    event_context: { eventOrigin: 'stepsPanel', section: 'pipeline' }
  },
  [USER_EVENTS.PROJECT_OPTIONS.SELECT]: {
    event_name: 'selectProjectOptionsMenuOption',
    event_context: { eventOrigin: 'projectsMenu' }
  },
  [USER_EVENTS.PROJECT_OPTIONS.TOGGLE]: {
    event_name: 'toggleProjectOptionsMenu',
    event_context: { eventOrigin: 'projectsMenu' }
  },
  [USER_EVENTS.REPORTS.ACTION_BAR.DOWNLOAD_DATA]: {
    event_name: 'downloadData',
    event_context: { eventOrigin: 'action_bar', section: 'export' },
    event_origin: 'reports'
  },
  [USER_EVENTS.REPORTS.EXPLORE_SECTION.TOGGLE]: {
    event_name: 'toggleExploreSection',
    event_context: { eventOrigin: 'explore_section', section: 'grid' },
    event_origin: 'reports'
  },
  [USER_EVENTS.SETTINGS.API_TOKENS.CREATE]: {
    event_name: 'createToken',
    event_context: { eventOrigin: 'settingsPage', section: 'apiTokens' }
  },
  [USER_EVENTS.SETTINGS.BILLING.CHANGE_ADDRESS]: {
    event_name: 'changeAddress',
    event_context: { eventOrigin: 'settingsPage', section: 'billing' }
  },
  [USER_EVENTS.SETTINGS.BILLING.CHANGE_METHOD]: {
    event_name: 'updatePayment',
    event_context: { eventOrigin: 'settingsPage', section: 'billing' }
  },
  [USER_EVENTS.SETTINGS.BILLING.VIEW_INVOICES]: {
    event_name: 'viewInvoices',
    event_context: { eventOrigin: 'settingsPage', section: 'billing' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.ADD]: {
    event_name: 'addConnector',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettings' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.CANCEL]: {
    event_name: 'cancelButton',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettings' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.CHECKOUT]: {
    event_name: 'checkoutConnector',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettings' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.OPEN]: {
    event_name: 'buyMoreConnectors',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettings' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.REMOVE.CANCEL]: {
    event_name: 'cancelButton',
    event_context: { eventOrigin: 'settingsPage', section: 'removeConnectorModal' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.REMOVE.CLICK]: {
    event_name: 'removeConnector',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettings' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.REMOVE.REMOVE]: {
    event_name: 'removeButton',
    event_context: { eventOrigin: 'settingsPage', section: 'removeConnectorModal' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.SEARCH]: {
    event_name: 'search',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettings' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.SUMMARY.BACK]: {
    event_name: 'backButton',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettingsSummaryModal' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.SUMMARY.CONFIRM]: {
    event_name: 'confirmButton',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettingsSummaryModal' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.SUMMARY.CLOSE]: {
    event_name: 'closeModal',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettingsSummaryModal' }
  },
  [USER_EVENTS.SETTINGS.CONNECTORS.SUMMARY.DELETE]: {
    event_name: 'deleteConnector',
    event_context: { eventOrigin: 'settingsPage', section: 'connectorSettingsSummaryModal' }
  },
  [USER_EVENTS.SETTINGS.PLAN.CHANGE]: {
    event_name: 'changePlan',
    event_context: { eventOrigin: 'settingsPage', section: 'plan' }
  },
  [USER_EVENTS.SETTINGS.PROJECT.NEW]: {
    event_name: 'newProject',
    event_context: { eventOrigin: 'settingsPage', section: 'projects' }
  },
  [USER_EVENTS.SETTINGS.PROJECT.OPEN_MENU]: {
    event_name: 'projectsMenuButton',
    event_context: { eventOrigin: 'settingsPage', section: 'projects' }
  },
  [USER_EVENTS.SETTINGS.SIDEBAR.NAVIGATE]: {
    event_name: 'navigateTo',
    event_context: { eventOrigin: 'settingsPage', section: 'sidebar' }
  },
  [USER_EVENTS.SETTINGS.STORAGE.OPEN]: {
    event_name: 'buyMoreStorage',
    event_context: { eventOrigin: 'settingsPage', section: 'plan' }
  },
  [USER_EVENTS.SETTINGS.USERS.ADD_TO_PROJECT]: {
    event_name: 'addNewUsers',
    event_context: { eventOrigin: 'settingsPage', section: 'manageUsers' }
  },
  [USER_EVENTS.SETTINGS.USERS.INVITE]: {
    event_name: 'inviteUsers',
    event_context: { eventOrigin: 'settingsPage', section: 'users' }
  },
  [USER_EVENTS.SETTINGS.USERS.MANAGE_PROJECT_OPEN_MODAL]: {
    event_name: 'openModal',
    event_context: { eventOrigin: 'settingsPage', section: 'manageUsers' }
  },
  [USER_EVENTS.SETTINGS.USERS.NEW_ROLE_DROPDOWN_SELECT]: {
    event_name: 'newUserRolesDropdownSelect',
    event_context: { eventOrigin: 'settingsPage', section: 'manageUsers' }
  },
  [USER_EVENTS.SETTINGS.USERS.OPEN]: {
    event_name: 'buyMoreUsers',
    event_context: { eventOrigin: 'settingsPage', section: 'plan' }
  },
  [USER_EVENTS.SETTINGS.USERS.REMOVE_FROM_PROJECT]: {
    event_name: 'removeUsers',
    event_context: { eventOrigin: 'settingsPage', section: 'manageUsers' }
  },
  [USER_EVENTS.SETTINGS.USERS.ROLE_DROPDOWN_SELECT]: {
    event_name: 'rolesDropdownSelect',
    event_context: { eventOrigin: 'settingsPage', section: 'manageUsers' }
  },
  [USER_EVENTS.VIEW_OPTIONS.APPLY_TEMPLATE]: {
    event_name: 'viewApplyTemplate',
    event_context: { eventOrigin: 'viewOptions' }
  },
  [USER_EVENTS.VIEW_OPTIONS.DELETE]: {
    event_name: 'viewDelete',
    event_context: { eventOrigin: 'viewOptions' }
  },
  [USER_EVENTS.VIEW_OPTIONS.DUPLICATE]: {
    event_name: 'viewDuplicate',
    event_context: { eventOrigin: 'viewOptions' }
  },
  [USER_EVENTS.VIEW_OPTIONS.RENAME]: {
    event_name: 'viewRename',
    event_context: { eventOrigin: 'viewOptions' }
  },
  [USER_EVENTS.VIEW_OPTIONS.RESET]: {
    event_name: 'viewReset',
    event_context: { eventOrigin: 'viewOptions' }
  },
  [USER_EVENTS.VIEW_OPTIONS.SAVE_AS_TEMPLATE]: {
    event_name: 'viewSaveTemplate',
    event_context: { eventOrigin: 'viewOptions' }
  },
  [USER_EVENTS.VIEW_OPTIONS.TOGGLE_DATA_SYNC]: {
    event_name: 'toggleDataSync',
    event_context: { eventOrigin: 'viewOptions' }
  }
}
