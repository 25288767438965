// API
import API from '@/api'
import globalApi from '@/api/global.api'
import { DATA_TYPES_MAP } from '@/constants'

// Transforms
import { transformIntegrationInfo, transformConnectionMetricsInfo } from './data-library.transform.js'

const fetchFromUrl = (projectId, folderResourceId, url) =>
  API.post(
    'weburls',
    { project_id: projectId, label_resource_id: folderResourceId, url },
    { customErrorHandling: true }
  ).catch((error) => error)

const addDataFromFile = (file) =>
  API.post('files', file, { timeout: 300000, customErrorHandling: true }).catch((error) => error)

const addWebhook = ({ projectId, folderResourceId, name, mode }) =>
  API.post(
    'webhooks',
    { project_id: projectId, label_resource_id: folderResourceId, name, mode },
    { customErrorHandling: true }
  ).catch((error) => error)

const addFolder = (projectId, name, parentFolderResourceId) =>
  API.post('labels', { project_id: projectId, name, parent_resource_id: parentFolderResourceId })

const getConnectorIdentities = (connectorHash, projectId) =>
  API.get(`integrations/${connectorHash}/identities?is_export=false&project_id=${projectId}`)

const getProfilesInfo = async (connectorHash, connectionId, projectId) => {
  const { data } = await API.get(
    `integrations/${connectorHash}/identities/${connectionId}?concern=profiles&project_id=${projectId}`
  )
  return await globalApi.getFutureRequest(data.future_id, 'getProfilesInfo')
}

const setNewConnection = async (connectorHash, fields, futuresStopper) => {
  const { data } = await API.post(`integrations/${connectorHash}/identities?is_export=false`, fields)
  return await globalApi.getFutureRequest(data.future_id, 'setNewConnection', futuresStopper)
}

const editConnection = async (connectorHash, connectionId, fields) => {
  const { data } = await API.patch(`integrations/${connectorHash}/identities/${connectionId}`, {
    patch: [
      { op: 'replace', path: 'identity', value: { ...fields, config_key: connectionId, identity_key: connectionId } }
    ]
  })
  return await globalApi.getFutureRequest(data.future_id)
}

const validateFileUrl = async (connectorHash, connectionId, fileUrl, projectId, futuresStopper) => {
  const { data } = await API.post(`integrations/${connectorHash}/identities/${connectionId}/dsConfigs`, {
    file_url: fileUrl,
    validate: true,
    project_id: projectId
  })
  return await globalApi.getFutureRequest(data.future_id, 'validateFileUrl', futuresStopper)
}

const validateFileList = async (connectorHash, connectionId, selectedFile, projectId, futuresStopper) => {
  const { data } = await API.post(`integrations/${connectorHash}/identities/${connectionId}/dsConfigs`, {
    file_list: selectedFile,
    validate: true,
    project_id: projectId
  })
  return await globalApi.getFutureRequest(data.future_id, 'validateFileList', futuresStopper)
}

const getConnectorIntegration = (connectorHash, projectId) =>
  API.get(`integrations/${connectorHash}?is_export=false&project_id=${projectId}`)

const deleteConnection = (connectorHash, connectionId, projectId) =>
  API.delete(`integrations/${connectorHash}/identities/${connectionId}?is_export=false&project_id=${projectId}`)

const getMetricsInfo = async (connectorHash, connectionId, projectId, profileId) => {
  const profileData = profileId ? `&profile=${profileId}` : ''

  const { data } = await API.get(
    `integrations/${connectorHash}/identities/${connectionId}?concern=ds_config_spec${profileData}&project_id=${projectId}`
  )
  return transformConnectionMetricsInfo(await globalApi.getFutureRequest(data.future_id))
}

const runTableQuery = async (connectorHash, connectionId, payload, futuresStopper) => {
  const { data } = await API.post(`integrations/${connectorHash}/identities/${connectionId}/dsConfigs`, payload)
  return await globalApi.getFutureRequest(data.future_id, 'runTableQuery', futuresStopper)
}

const editQuery = (connectorHash, connectionId, configKey, queryString, datasetId) =>
  API.patch(`integrations/${connectorHash}/identities/${connectionId}/dsConfigs/${configKey}`, {
    patch: [
      {
        op: 'replace',
        path: 'ds_config',
        value: {
          query_string: queryString,
          hidden_query: queryString,
          datasource_id: datasetId,
          validate: true
        }
      }
    ]
  })

const editSchedule = ({
  datasetId,
  retrievalFrequencyUnit,
  retrievalFrequency,
  firstRetrievalDate,
  datasetMode,
  isFirstRetrievalDateNow
}) =>
  API.post('schedule', {
    params: {
      ds_id: datasetId,
      schedule_related_params: getScheduleRelatedParams({ datasetMode, isFirstRetrievalDateNow }),
      recurrence_schedule: getScheduleRecurrenceInfo({
        retrievalFrequency,
        retrievalFrequencyUnit,
        firstRetrievalDate
      })
    }
  })

const addThirdPartyData = (payload) =>
  API.post(
    'datasets',
    {
      params: {
        integration_key: payload.connectorKey,
        identity_key: payload.identityKey,
        project_id: payload.projectId,
        ds_config: {
          data_start_date: payload.dataStartingFrom,
          file_url: payload.fileUrl,
          sheet_name: payload.sheetName,
          tables: payload.table,
          datapull_options: payload.table,
          hidden_query: payload.queryString,
          query_box_label: '',
          query_string: payload.queryString,
          profile: payload.profile,
          ds_name: payload.datasetName,
          file_list: payload.selectedFile,
          documents: payload.selectedFile,
          folder_list: payload.folderToPull,
          name_pattern: payload.filePattern,
          data_pull_file: payload.pullMode,
          column_types_as_text: payload.isTreatColumnAsTextActive,
          ...payload.selectedMetrics
        },
        schedule_related_params: getScheduleRelatedParams(payload),
        recurrence_info: getScheduleRecurrenceInfo(payload)
      },
      label_resource_id: payload.folderResourceId,
      create_from_cloud: true
    },
    { customErrorHandling: true }
  ).catch((error) => error)

function getScheduleRelatedParams(payload) {
  return {
    schedule_type:
      (payload.fileUrl && !payload.sheetName) || (payload.selectedFile && !payload.pullMode)
        ? null
        : payload.scheduleType || 'MOMENT',
    firstPullAt: payload.isFirstRetrievalDateNow,
    on_refresh_action: payload.datasetMode || 'REPLACE',
    unique_sequence_column: payload.uniqueSequenceColumn && {
      display_name: payload.uniqueSequenceColumn.headerName,
      type: Object.keys(DATA_TYPES_MAP).find((key) => DATA_TYPES_MAP[key] == payload.uniqueSequenceColumn.datatype)
    }
  }
}

function getScheduleRecurrenceInfo(payload) {
  return {
    INTERVAL: [null, 'WEEKLY', 'MONTHLY'].includes(payload.retrievalFrequency) ? 1 : payload.retrievalFrequencyUnit,
    FREQUENCY: payload.retrievalFrequency,
    START: payload.firstRetrievalDate,
    UNTIL: payload.dataEndingOn || payload.endRetrievalSpecifiedTime,
    ...(!(payload.fileUrl || payload.selectedFile) && {
      BYMONTHDAY: (payload.retrievalFrequency == 'MONTHLY' && [payload.retrievalFrequencyUnit]) || [],
      BYWEEKDAY: (payload.retrievalFrequency == 'WEEKLY' && [payload.retrievalFrequencyUnit]) || []
    })
  }
}

export default {
  fetchFromUrl,
  addDataFromFile,
  addWebhook,
  getConnectorIdentities,
  getProfilesInfo,
  getConnectorIntegration: async (connectorHash, projectId) =>
    transformIntegrationInfo(await getConnectorIntegration(connectorHash, projectId)),
  deleteConnection,
  getMetricsInfo,
  runTableQuery,
  editQuery,
  editSchedule,
  addThirdPartyData,
  setNewConnection,
  editConnection,
  validateFileUrl,
  validateFileList,
  addFolder
}
